<template>
  <b-modal
    id="GrantsConstraintsPlanInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/GrantsConstraintsPlans.svg" class="icon-lg" />
        {{ $t("GrantsConstraintsPlans.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlan.grantsConstraintsPlansCode"
        :title="$t('GrantsConstraintsPlans.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlan.grantsConstraintsPlansNameAr"
        :title="$t('GrantsConstraintsPlans.nameAr')"
        :imgName="'GrantsConstraintsPlans.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlan.grantsConstraintsPlansNameEn"
        :title="$t('GrantsConstraintsPlans.nameEn')"
        :imgName="'GrantsConstraintsPlans.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlan.grantsConstraintsPlansNameUnd"
        :title="$t('GrantsConstraintsPlans.nameUnd')"
        :imgName="'GrantsConstraintsPlans.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grantsConstraintsPlan.grantsConstraintsPlansNote"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            grantsConstraintsPlan.dateActionAdd,
            grantsConstraintsPlan.TimeActionAdd
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { formateDateTimeLang } from "@/utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["grantsConstraintsPlan"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>
