import PaginationData from "@/models/general/PaginationData";
import GrantsConstraintsPlan from "./GrantsConstraintsPlan";
import GrantsConstraintsPlansFilter from "./GrantsConstraintsPlansFilter";

export default class GrantsConstraintsPlans {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.grantsConstraintsPlansData = [];
    this.grantsConstraintsPlan = new GrantsConstraintsPlan();
    this.filterData = new GrantsConstraintsPlansFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.grantsConstraintsPlansPagination);
    this.grantsConstraintsPlansData =
      data.grantsConstraintsPlansPagination.grantsConstraintsPlansData;
  }
}
